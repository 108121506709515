<template>
    <vx-card title="Canvas - Handover">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="$route.params.status">
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Handovered">
                    <tab-handovered></tab-handovered>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
import TabHandover from "./TabHandover.vue";
import TabHandovered from "./TabHandovered.vue";
import TabComplete from "./TabComplete.vue";

export default {
    components: {
        TabHandover,
        TabHandovered,
        TabComplete,
    },
    data() {
        return {
            activeTab: "Handover",
            render: true,
            settingValue: "",
        }
    },
    mounted() {},
    methods: {}
}
</script>